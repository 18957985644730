import {AppRoutes} from "~/utils/other/AppRoutes";
import {userStore} from "~/store/userStore";
import {navigateTo} from "#app";
import {companyStore} from "~/store/companyStore";
import {authenticationStore} from "~/store/authenticationStore";

export default defineNuxtRouteMiddleware( async (to, from) => {
    const useCompanyStore = companyStore();
    const useAuthenticationStore = authenticationStore();

    const companyCode = to.params.companyname
    const fullPath  = to.fullPath
    let isHomeRoute = false;
    if(companyCode){
        const splitPaths = fullPath.split(companyCode.toString());
        const firstPath = splitPaths[0];
        isHomeRoute = firstPath === '/';
    }
    if(isHomeRoute){

        useCompanyStore.setCompanyCode(companyCode.toString());
        if(companyCode){
            await useCompanyStore.loadCompanyDetailsByCode(companyCode.toString().trim(), true);
        }
    }

    setTimeout(()=>{
        try{
            const useUserStore = userStore()
            let signedInUser:boolean = false;
            const loginRoute = '/'+useCompanyStore.getCompanyCode;

            if(useUserStore.loggedInUser.id){
                signedInUser = true;
            }

            if(to.fullPath == '/' || to.fullPath==loginRoute || to.fullPath==AppRoutes.AuthMatchOtp){
                if(signedInUser){
                    const navigations = useUserStore.getSidebarNavigations(useUserStore.getLoggedInUser, useCompanyStore.getCompanyDetailsLocal);
                    return navigateTo({ path: navigations[0].href, replace: true});
                }else{
                    return;
                }
            }else if (to.fullPath!=loginRoute){
                if (!signedInUser) {
                    useAuthenticationStore.setFailedRouteBeforeLogin(from.path);
                    return navigateTo({ path: loginRoute , replace: true});
                } else{
                    return;
                }
            }
        }
        catch (e){
            console.log(e)
        }
        return;
    }, 100)
})